<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getList')">
          <div class="row mb-4">
            <div class="col-md-4 filter">
              <input
                class="form-control"
                :value="payload.id_invitation"
                @input="
                  $emit('setPayload', {
                    name: 'id_invitation',
                    value: parseInt($event.target.value),
                  })
                "
                type="number"
                placeholder="Id invito"
              />
            </div>
            <div class="col-md-4 filter">
              <InputList
                :apiLink="API.LOOKUP_PERSON_SEARCH"
                payloadParameterTextName="surname"
                payloadParameterTextNameSec="name"
                payloadParameterTextNameTer="date_of_birth"
                parameterToShow="nome"
                @isSelectedElement="
                  $emit('setPayload', {
                    name: 'id_person',
                    value: $event.id,
                  })
                "
                @cancelElement="
                  $emit('setPayload', {
                    name: 'id_person',
                    value: null,
                  })
                "
                placeholder="Persona"
                getEntireRecord
                :emitEventOnCancel="false"
              />
            </div>
            <div
              class="col-md-4 filter text-center my-auto d-flex align-items-center"
            >
              <label>Mostra gli elementi con errori</label>
              <input
                class="ms-2"
                @input="
                  $emit('setPayload', {
                    name: 'error',
                    value: $event.target.checked,
                  })
                "
                type="checkbox"
                :value="true"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('getList')"
              :disabled="(!id_year && !id_event) || !loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                $emit('changeCurrentPage', 1);
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { format } from "@/functions/formatDate";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import { useStore } from "vuex";
import InputList from "../utility/InputList.vue";

export default defineComponent({
  name: "filters-log-crionet",
  emits: ["resetFilters", "getList", "setPayload", "changeCurrentPage"],
  props: {
    loaded: {
      type: Boolean,
    },
    payload: { type: Object },
  },
  components: { InputList },
  setup() {
    const store = useStore();
    const id_year = computed(() => store.getters.id_year);
    const id_event = computed(() => store.getters.id_event);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ee|yy" }
    );
    get_lookups();
    const date = ref(null);
    return {
      date,
      lookups,
      format,
      moment,
      id_year,
      id_event,
      API,
    };
  },
  computed: {
    lookupYears() {
      return this.lookups && this.lookups.result && this.lookups.result[1]
        ? this.lookups.result[1].years
        : [];
    },
    lookupEvents() {
      return this.lookups && this.lookups.result && this.lookups.result[0]
        ? this.lookups.result[0].events
        : [];
    },
  },
});
</script>

<style></style>
