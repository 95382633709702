<template>
  <div
    class="modal fade modal-xl"
    tabindex="-10"
    id="modal_view_richiesta_crionet"
  >
    <div class="modal-dialog">
      <div>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Visualizza richiesta</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modal_view_richiesta_crionet_closex"
              @click="closeModal"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5 repeater-form bg-even">
                <div
                  class="row pb-4 border-top border-secondary"
                  v-for="(person, index) in payload"
                  :key="index"
                >
                  <div class="col-3 my-3">
                    <img
                      style="max-width: 12rem"
                      :src="person.Photo"
                      :alt="`${person.FirstName} ${person.LastName}`"
                    />
                  </div>

                  <div class="col-9 my-3">
                    <div>
                      <p class="title"><b>Richiesta</b></p>
                      <div class="row body">
                        <!-- Prima colonna: metà degli elementi -->
                        <div class="col-md-6">
                          <!-- v-for="(value, key) in person" :key="key" -->
                          <div
                            v-for="(value, key) in firstHalf(person)"
                            :key="key"
                          >
                            <p style="word-wrap: break-word">
                              <b>{{ key }}</b
                              >: {{ formatValue(key, value) }}
                            </p>
                          </div>
                        </div>
                        <!-- Seconda colonna: metà degli elementi -->
                        <div class="col-md-6">
                          <div
                            v-for="(value, key) in secondHalf(person)"
                            :key="key"
                          >
                            <p>
                              <b>{{ key }}</b
                              >: {{ formatValue(key, value) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <p class="title"><b>Risposta</b></p>
                      <div class="row body">
                        <div v-for="(barcode, key) in response" :key="key">
                          <template v-if="barcode.fitpId == person.FitpID">
                            <div v-for="(value, key) in barcode" :key="key">
                              <p>
                                <b>{{ key }}</b
                                >: {{ formatValue(key, value) }}
                              </p>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modal_view_richiesta_crionet_close_button"
              @click="closeModal"
            >
              Chiudi
            </button>
          </div>
          <!-- <Loader v-else /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add-persona-modal",
  components: {},
  props: {
    selected_inv: {
      type: Object,
    },
  },
  emits: ["getList", "closeModal"],
  setup() {
    const formatValue = (key, value) => {
      // Formatta la data di nascita
      if (key === "BirthDate") {
        return value;
      }
      // Restituisci il valore di altre proprietà come sono
      return value;
    };

    const firstHalf = (obj) => {
      const entries = Object.entries(obj);
      const half = Math.ceil(entries.length / 2);
      return Object.fromEntries(entries.slice(0, half));
    };
    const secondHalf = (obj) => {
      const entries = Object.entries(obj);
      const half = Math.ceil(entries.length / 2);
      return Object.fromEntries(entries.slice(half));
    };
    return {
      firstHalf,
      secondHalf,
      formatValue,
    };
  },
  computed: {
    payload() {
      let objToReturn = null;
      if (this.selected_inv) {
        const parsedObject = JSON.parse(this.selected_inv.request);
        objToReturn = parsedObject.results;
      }
      return objToReturn;
    },
    response() {
      let objToReturn = null;
      if (this.selected_inv) {
        const parsedObject = JSON.parse(this.selected_inv.response);
        objToReturn = parsedObject.credentials;
      }
      return objToReturn;
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}
.bg-even {
  background-color: rgb(248, 250, 251);
}
.bg-odd {
  background-color: rgb(255, 255, 255);
}
.title {
  font-size: 1.25rem; /* Imposta una dimensione maggiore del font */
  font-weight: bold; /* Rende il testo in grassetto */
  color: #333; /* Colore scuro per una buona leggibilità */
  background-color: #f0f0f0; /* Colore di sfondo chiaro */
  padding: 5px 10px; /* Aggiungi un po' di spazio intorno al testo */
  border-radius: 5px; /* Angoli arrotondati per un aspetto più pulito */
  margin-bottom: 10px; /* Distanza tra il titolo e il contenuto successivo */
}
.body {
  padding: 5px 10px;
}
</style>
