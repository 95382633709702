<template>
  <div>
    <ModalViewRichiestaCrionet :selected_inv="selected_inv" />
    <div class="row mt-5">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="objectListFromProps && objectListFromProps.record > 0">
            {{
              payload.rowstoskip + payload.fetchrows - (payload.fetchrows - 1)
            }}
            -
            {{
              payload.rowstoskip + payload.fetchrows >
              objectListFromProps.record
                ? objectListFromProps.record
                : payload.rowstoskip + payload.fetchrows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di
          <b>{{
            objectListFromProps && objectListFromProps.record
              ? objectListFromProps.record
              : 0
          }}</b>
        </div>
      </div>
    </div>
    <br />
    <!-- :key="rerenderDatatable" -->
    <template v-if="!payload.id_year || !payload.id_event">
      <div class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5">
        <span class="text-gray-700 ps-3 fs-2">
          Inserire anno ed evento per controllare i pagamenti
        </span>
      </div>
    </template>
    <Datatable
      v-else
      :key="loaded"
      :table-header="tableHeader"
      :table-data="
        objectList && objectListFromProps ? objectListFromProps.results : []
      "
      :status="status"
      :loading="!loaded"
      :enableItemsPerPageDropdown="
        !from_invitations && !from_invitations_consigliere
      "
      :total="objectListFromProps ? objectListFromProps.record : 0"
      :rowsPerPage="payload.fetchrows"
      :currentPage="currentPage"
      :order="payload.sortorder"
      :sortLabel="payload.sortcolumn"
      @sort="
        $emit('setPayload', {
          name: 'sortcolumn',
          value: $event.columnName,
        });
        $emit('setPayload', {
          name: 'sortorder',
          value: $event.order,
        });
        $emit('getList');
      "
      @items-per-page-change="
        $emit('setPayload', {
          name: 'fetchrows',
          value: $event,
        });
        $emit('getList');
      "
      @current-change="
        $emit('changeCurrentPage', $event);
        $emit('setPayload', {
          name: 'rowstoskip',
          value: payload.fetchrows * ($event - 1),
        });
        $emit('getList');
      "
      @resetFilters="$emit('resetFilters')"
      @checkboxClicked="
        $emit('checkedElement', objectListFromProps.results);
        setChecked($event.target.checked);
      "
    >
      <template v-slot:cell-surname="{ row: data }"
        ><div class="">
          {{ data.surname }}
        </div>
      </template>
      <template v-slot:cell-name="{ row: data }"
        ><div class="">
          {{ data.name }}
        </div>
      </template>
      <template v-slot:cell-birthdate="{ row: data }"
        ><div class="">
          {{ data.birthdate }}
        </div>
      </template>
      <template v-slot:cell-date_time_request_response="{ row: data }"
        ><div class="tab-long">
          {{ moment(data.date_time_request).format("DD/MM/YYYY hh:mm") }} <br />
          {{ moment(data.date_time_response).format("DD/MM/YYYY hh:mm") }}
        </div>
      </template>
      <template v-slot:cell-group_sender="{ row: data }"
        ><div class="d-flex tab-long">
          <div>
            <EtichettaGruppo :label="data.group" :bg_color="data.group_color" />
          </div>
        </div>
      </template>
      <template v-slot:cell-error="{ row: data }"
        ><div class="">
          <span class="text-success" v-if="data.error">SI</span>
          <span class="text-danger" v-else>NO</span>
        </div>
      </template>
      <template v-slot:cell-id_invitation="{ row: data }"
        ><div class="">
          {{ data.id_invitation }}
        </div>
      </template>
      <template v-slot:cell-options="{ row: data }"
        ><div class="d-flex">
          <button
            class="btn btn-sm dropdown"
            type="button"
            @click="selected_inv = data"
            data-bs-toggle="modal"
            data-bs-target="#modal_view_richiesta_crionet"
          >
            <i class="bi bi-eye text-dark fs-4"></i>
          </button></div
      ></template>
      <template v-slot:cell-integration="{ row: data }">
        <i
          v-if="data.integration"
          class="bi bi-exclamation-triangle-fill text-danger text-center"
        ></i>
        <div v-else></div>
      </template>
      <!-- <template v-slot:cell-options="{ row: data }"
        ><div class="btn-group">
          <button
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_session"
            type="button"
            @click="selected_id_session = data.id"
          >
            <i class="bi bi-pen text-dark fs-4"></i>
          </button>

          <button
            class="btn btn-sm dropdown p-1"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_remove_person_from_session"
            @click="
              selected_id_session_del = data.id;
              selected_session_name = data.name;
            "
          >
            <i class="bi bi-person-fill-x text-dark fs-4"></i>
          </button>
        </div>
      </template> -->
    </Datatable>
  </div>
</template>

<script>
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { ref, watch } from "vue";
import { alertFailed, callFunctionAfterAlert } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { formatDateITA } from "@/functions/formatDate.js";
import ModalViewRichiestaCrionet from "./ModalViewRichiestaCrionet.vue";
import moment from "moment";
import EtichettaGruppo from "@/components/groups/EtichettaGruppo.vue";
export default {
  name: "TableBiglietteria",
  components: {
    Datatable,
    ModalViewRichiestaCrionet,
    EtichettaGruppo,
  },
  emits: [
    "getList",
    "resetFilters",
    "setPayload",
    "checkedElement",
    "changeCurrentPage",
  ],
  props: {
    objectList: {
      type: Object,
    },
    loaded: {
      type: Boolean,
    },
    status: {
      type: Number,
    },
    payload: {
      type: Object,
    },
    pressedSearch: {
      type: Boolean,
    },
    id_year: {
      type: Number,
    },
    id_event: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const selected_inv = ref(null);
    const tableHeader = ref([
      {
        key: "integration",
        sortable: false,
      },
      {
        name: "ID invito",
        key: "id_invitation",
      },
      {
        name: "Cognome",
        key: "surname",
      },
      {
        name: "Nome",
        key: "name",
      },

      {
        name: "Data di nascita",
        key: "birthdate",
        sortable: false,
      },
      {
        name: "Gruppo Invitante",
        key: "group_sender",
      },
      {
        name: "Data e ora richiesta e risposta",
        key: "date_time_request_response",
        sortable: false,
      },
      {
        name: "Errore",
        key: "error",
        sortable: false,
      },
      // {
      //   name: "Pagamento",
      //   key: "payed",
      // },
      // {
      //   name: "Sessioni",
      //   key: "sessions",
      //   sortable: false,
      // },
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const objectListFromProps = ref({ ...props.objectList });
    watch(
      () => props.objectList,
      () => {
        objectListFromProps.value = { ...props.objectList };
      }
    );

    const setChecked = (isChecked) => {
      objectListFromProps.value.results.forEach((element, idx) => {
        if (props.is_ospite) {
          if (element.checked != isChecked && idx < 2) {
            element.checked = isChecked;
            const checkbox = document.getElementById(
              `checkbox_session${element.id}`
            );
            if (checkbox) checkbox.click();
          }
        } else {
          if (element.checked != isChecked && !element.payed) {
            element.checked = isChecked;
            const checkbox = document.getElementById(
              `checkbox_session${element.id}`
            );
            if (checkbox) checkbox.click();
          }
        }
      });
    };

    const inserirePagamentoTutti = (arr) => {
      const id_selected = [];
      arr.forEach((element) => {
        id_selected.push(element.id_movement);
      });
      const JSONid_selected = JSON.stringify(id_selected);
      callApiPayment(JSONid_selected, "all");
    };
    const inserirePagamentoSelezionati = (arr) => {
      const id_selected = [];
      arr.forEach((element) => {
        if (element.checked) id_selected.push(element.id_movement);
      });
      if (!id_selected.length) {
        alertFailed("Nessun invitato selezinato!");
        return;
      }
      const JSONid_selected = JSON.stringify(id_selected);

      callApiPayment(JSONid_selected, "sel");
    };

    const callApiPayment = async (movements, label) => {
      await callFunctionAfterAlert(
        props.id_invitation
          ? API.TIKCETING_PAYMENT_CONFIRM
          : API.TIKCETING_PAYMENT_CONFIRM,
        { movements },
        `Sei sicuro di inserire il pagamento ${
          label == "sel"
            ? "per gli invitati selezionati"
            : "per tutti gli invitati in lista"
        } ?`,
        "Pagamenti inseriti con successo",
        "Attenzione! Si è verificato un errore. Riprovare più tardi"
      ).then((isAddedorModifiedsession) => {
        emit("getList");
      });
    };
    const id_invitation = ref(0);
    return {
      id_invitation,
      inserirePagamentoSelezionati,
      inserirePagamentoTutti,
      setChecked,
      objectListFromProps,
      tableHeader,
      formatDateITA,
      selected_inv,
      moment,
    };
  },
};
</script>

<style scoped></style>
