<template>
  <div>
    <FiltersLogCrionet
      @getList="get_log_crionet"
      @resetFilters="resetFilters"
      :loaded="loaded_log_crionet"
      @setPayload="setPayload"
      :payload="payload"
      @changeCurrentPage="currentPage = $event"
    />
    <TableLogCrionet
      @getList="get_log_crionet"
      @resetFilters="resetFilters"
      :objectList="ticketing"
      :loaded="loaded_log_crionet"
      :status="status_log_crionet"
      @setPayload="setPayload"
      :payload="payload"
      :id_event="payload.id_event"
      :id_year="payload.id_year"
      :currentPage="currentPage"
      @checkedElement="$emit('checkedElement', $event)"
      @changeCurrentPage="currentPage = $event"
    />
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/functions/trimInput";
import FiltersLogCrionet from "../components/logCrionet/FiltersLogCrionet.vue";
import TableLogCrionet from "../components/logCrionet/TableLogCrionet.vue";
import { useApiCall } from "@/composables/apiCall";
import { API } from "@/enums/costanti";
import { useStore } from "vuex";

export default defineComponent({
  name: "ticketing",
  components: {
    FiltersLogCrionet,
    TableLogCrionet,
  },
  emits: ["listLength"],
  setup(props, { emit }) {
    const store = useStore();
    const id_year = computed(() => store.getters.id_year);
    const id_event = computed(() => store.getters.id_event);
    const payload = ref({
      id_person: null,
      error: null,
      id_invitation: null,
      id_event: id_event.value,
      id_year: id_year.value,
      sortcolumn: "surname",
      sortorder: "asc",
      rowstoskip: 0,
      fetchrows: 25,
    });
    const setPayload = ({ name, value }) => {
      payload.value[name] = value;
    };

    const {
      element: ticketing,
      loaded: loaded_log_crionet,
      getElement: get_log_crionet,
      status: status_log_crionet,
    } = useApiCall(API.ANAGRAPHICS_PEOPLE_CRIONET_EXPORT_LOG_LIST, payload);

    onMounted(async () => {
      if (!props.from_invitations) {
        setCurrentPageBreadcrumbs("Biglietteria", payload);
      }
      if (payload.value.id_year && payload.value.id_event)
        await get_log_crionet();
    });
    watch([id_year, id_event], async () => {
      payload.value.id_year = id_year.value;
      payload.value.id_event = id_event.value;
      if (id_year.value && id_event.value) {
        await get_log_crionet();
      }
    });

    const resetFilters = () => {
      payload.value = {
        id_person: null,
        error: null,
        id_invitation: null,
        id_event: id_event.value,
        id_year: id_year.value,
        sortcolumn: "surname",
        sortorder: "asc",
        rowstoskip: 0,
        fetchrows: 25,
      };
      get_log_crionet();
    };
    const checked_setted = ref(false);

    const currentPage = ref(1);

    return {
      currentPage,
      checked_setted,
      resetFilters,
      setPayload,
      get_log_crionet,
      ticketing,
      loaded_log_crionet,
      status_log_crionet,
      trimInput,
      payload,
      id_year,
      id_event,
    };
  },
});
</script>
